<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ $t("Add a package") }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 600px;"
      >
        <v-row class="pt-5">
          <v-col
            cols="12"
          >
            <div
              class="me-3 "
              style="width: 100px"
            >
              <span>{{ $t("Image") }}</span>
              <file-upload
                v-model="item.image"
                add-class="mx-auto justify-center"
                :label="$t('Upload new photo')"
                folder="images/users/avatars/"
                :btn="true"
                :extra="false"
                :hide-label="false"
                icon-name="mdi-pencil"
                :icon="false"
                :show-download="false"
                inputname="file"
                :crop="true":aspect-ratio="16/9"
                :max-crop-height="1400"
                :max-crop-width="700"
                accept="image/*"
              >
                <template #input>
                  <v-avatar
                    :color="item.image ? '' : 'primary'"
                    :class="item.image ? '' : 'v-avatar-light-bg primary--text'"
                    size="100"
                    rounded
                    class="mb-4"
                  >
                    <v-img
                      v-if="item.image"
                      :lazy-src="require(`@/assets/images/avatars/1.png`)"
                      :src="item.image || require(`@/assets/images/avatars/1.png`)"
                    ></v-img>
                    <span
                      v-else
                      class="font-weight-semibold text-5xl"
                    >{{ avatarText(item.name) }}</span>
                    <v-icon
                      size="20"
                      color="primary"
                      style="position: absolute;bottom: 10px; left: 10px"
                    >
                      {{ icons.mdiCloudUploadOutline }}
                    </v-icon>
                  </v-avatar>
                </template>
              </file-upload>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
          >
<!--            <v-text-field

            ></v-text-field>-->
            <translatable-text-field
              v-model="item.name"
             outlined
             dense
             :error-messages="errorMessages.name"
             :label="$t('Name')"
             :placeholder="$t('Name')"
              hide-details="auto" ></translatable-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.pick_location"
              outlined
              dense
              :error-messages="errorMessages.pick_location"
              :label="$t('Pickup location')"
              :placeholder="$t('Pickup location')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.drop_location"
              outlined
              dense
              :error-messages="errorMessages.drop_location"
              :label="$t('Drop location')"
              :placeholder="$t('Drop location')"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.total_rides"
              outlined
              dense
              type="number"
              :error-messages="errorMessages.total_rides"
              :label="$t('Total rides')"
              :placeholder="$t('Total rides')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.duration"
              outlined
              dense
              type="number"
              :error-messages="errorMessages.duration"
              :label="$t('Duration')"
              :placeholder="$t('Duration')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-text-field
              v-model="item.amount"
              outlined
              dense
              type="number"
              :error-messages="errorMessages.amount"
              :label="$t('Amount')"
              :placeholder="$t('Amount')"
              hide-details="auto"
            ></v-text-field>
          </v-col>





          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-select
              v-model="item.type"
              :items="['monthly', 'weekly','daily','yearly']"
              :label="$t('Period')"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
          >
            <v-select
              v-model="item.package_type"
              :items="[{ id: 'transport', name: 'Transport' },
          { id: 'delivery', name: 'Delivery' }]"
              :label="$t('Type')"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </v-select>
          </v-col>
          <v-col col="12">
            <v-row>
              <v-col
                cols="6"
              >
                <v-menu :close-on-content-click="false" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field  v-model="item.start_color" outlined
                                  v-bind="attrs"
                                  v-on="on"
                                  dense
                                  :error-messages="errorMessages.start_color"
                                  :label="$t('Start color')"
                                  :placeholder="$t('Start color')"
                                  hide-details="auto"/>
                  </template>
                  <v-color-picker
                    v-model="item.start_color"
                    mode="hex"
                    outlined
                    dense
                    :error-messages="errorMessages.start_color"
                    :label="$t('Start color')"
                    :placeholder="$t('Start color')"
                    hide-details="auto"
                  ></v-color-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="6"
              >
                <v-menu :close-on-content-click="false" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="item.end_color" outlined
                                  v-bind="attrs"
                                  v-on="on"
                                  dense
                                  :error-messages="errorMessages.end_color"
                                  :label="$t('End color')"
                                  :placeholder="$t('End color')"
                                  hide-details="auto"/>
                  </template>
                  <v-color-picker
                    v-model="item.end_color"
                    mode="hex"
                    outlined
                    dense
                    :error-messages="errorMessages.end_color"
                    :label="$t('End color')"
                    :placeholder="$t('End color')"
                    hide-details="auto"
                  ></v-color-picker>
                </v-menu>
              </v-col>


            </v-row>
          </v-col>
          <v-col
            cols="12"
          >
            <translatable-textarea
              v-model="item.description"

              :label="$t('Decription')"
              outlined
              dense
              hide-details="auto"
              clearable
            >
            </translatable-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="dialog = false"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Save") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"

      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>

</template>

<script>
import {
  mdiClose, mdiPencilOutline, mdiPencilOffOutline, mdiCalendar,mdiCloudUploadOutline
} from '@mdi/js'
import { getIndex, getItem } from '@core/utils'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { format, parse } from 'date-fns'
import store from '@/store'
import controller from './Controller'
import authorsController from '@/views/apps/author/author-list/useAuthorsList'
import AvatarName from '@/components/AvatarName'
import { avatarText } from '@core/utils/filter'
import AuthorForm from '@/views/apps/author/author-list/AuthorForm'
import useAuthorsList from '@/views/apps/author/author-list/useAuthorsList'
import DatePicker from '@/components/DatePicker'
import FileUpload from "@/components/file_upload";
import TranslatableTextField from "@/components/TranslatableTextField";
import TranslatableTextarea from "@/components/TranslatableTextarea";

export default {
  components: {
    TranslatableTextarea,
    TranslatableTextField, FileUpload, DatePicker, AuthorForm, AvatarName },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()
    const {} = authorsController()
    const errorMessages = ref({ })
    const valid = ref(false)
    const modalReceipt = ref(false)
    const modalResponse = ref(false)
    const modalProposition = ref(false)
    const isFormAuthorActive = ref(false)
    const loading = ref(false)
    const isLoadingPackages = ref(false)
    const authors = ref([])
    const fees = ref([])
    const isLoadingAuthors = ref(false)
    const search = ref(null)
    const minDate = new Date().toISOString().substr(0, 10)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })
    if (item.value.author && authors.value.findIndex(ele => ele.id === item.value.author_id) < 0) {
      authors.value.push(item.value.author)
    }
    watch(item, val => {
      if (item.value.author && authors.value.findIndex(ele => ele.id === item.author_id) < 0) {
        authors.value.push(item.value.author)
      }
    }, { deep: true })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }
    const formatDate = (date, f) => format(date, f)


    const onSubmit = () => {
      if (valid.value) {


        loading.value = true
        store.dispatch('app-package/addItem', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }
            resetItem()

            // emit('update:is-form-active', false)

            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }



    // onMounted(searchParticular)


    return {
      minDate,
      resetItem,
      form,
      isLoadingPackages,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,

      item,
      valid,
      loading,
      validate,
      avatarText,
      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
